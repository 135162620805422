import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "@/router/index";
// import router from './router/index'
Vue.use(ElementUI);

Vue.use(VueScrollTo, {
  duration: 1000, // 过渡持续时间（以毫秒为单位）
  easing: "ease", // 缓动函数，例如 'ease' 或 'linear'
  offset: -50, // 偏移量，用于微调滚动位置
});
Vue.config.productionTip = false;
// 路由变化时记录页面视图
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});
new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
}).$mount("#app");
