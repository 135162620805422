<template>
  <div class="bg" id="App">
    <!-- 头部 -->
    <el-container class="container">
      <el-header>
        <div class="header">
          <div class="logo">
            <img src="@/nav/log.png" height="80px" />
            <div>
              <div class="titlefont">AI智慧教育暨教育质量监测评价平台</div>
              <div></div>
            </div>
          </div>
          <div class="title_right">
            <div>
              <img src="@/nav/dp.png" />
            </div>
            <div class="title_right_text">综合实践基地认证</div>
          </div>
        </div>
      </el-header>
      <el-main style="overflow-y: hidden !important">
        <div class="conter">
          <!-- nav导航 -->
          <div class="topnav">
            <a
              href="#card"
              v-scroll-to="{ element: '#card' }"
              @click="activeName = '0'"
              style="border-radius: 2px 0 0 2px"
              >学前教育</a
            >
            <!-- <a
                href="#card"
                @click="activeName = '2'"
                v-scroll-to="{ element: '#card' }"
                >义务教育</a
              > -->
            <a
              href="#card"
              @click="activeName = '1'"
              v-scroll-to="{ element: '#card' }"
              >小学教育</a
            >
            <a
              href="#card"
              @click="activeName = '2'"
              v-scroll-to="{ element: '#card' }"
              >初中教育</a
            >
            <a
              href="#card"
              @click="activeName = '3'"
              v-scroll-to="{ element: '#card' }"
              >高中教育</a
            >
            <a
              href="#card"
              @click="activeName = '4'"
              style="border: none; border-radius: 0 2px 2px 0"
              v-scroll-to="{ element: '#card' }"
              >特殊教育</a
            >
          </div>
          <!-- 轮播图 -->
          <el-carousel
            type="card"
            height="400px"
            class="swiper"
            indicator-position="none"
          >
            <el-carousel-item v-for="item in swiperList" :key="item">
              <img
                style="border-radius: 3px"
                height="100%"
                :src="require(`@/nav/swiper/${item}`)"
              />
            </el-carousel-item>
          </el-carousel>
          <div class="cardList">
            <!-- 左侧导航 -->
            <div class="left">
              <el-tabs v-model="activeName" id="card">
                <el-tab-pane
                  v-for="item in login"
                  :key="item.stage"
                  :name="item.stage"
                >
                  <span class="stageLabel" slot="label">{{ item.name }}</span>
                  <div class="stageBoxP">
                    <div class="boxStage">
                      <a
                        class="ebox"
                        v-for="(e, index) in item.children"
                        :key="index"
                        :href="e.url"
                        target="_blank"
                      >
                        <div>
                          <img
                            :src="require(`@/nav/navsvg/${e.icon}.svg`)"
                            height="60px;"
                            style=""
                          />
                          <img
                            :src="require(`@/nav/navsvgw/${e.icon}.svg`)"
                            height="60px;"
                          />
                        </div>
                        <div class="elabel" style="width: 160px">
                          {{ e.name }}
                        </div>
                      </a>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <!-- 新闻资讯 -->
              <el-tabs v-model="mesg">
                <el-tab-pane
                  v-for="item in msgList"
                  :key="item.id"
                  :name="item.id"
                >
                  <span class="stageLabel" slot="label">{{ item.name }}</span>
                  <div class="mesgList" style="height: 890px; overflow-y: auto">
                    <a
                      v-for="e in item.children"
                      :key="e.id"
                      @click="jump(e)"
                      id="a_link"
                      style="display: flex; align-items: center"
                    >
                      <div
                        class="dot"
                        style="
                          width: 5px;
                          height: 5px;
                          border-radius: 50%;
                          background-color: #551aa9;
                        "
                      ></div>
                      <div id="linkClass">{{ e.title }}</div>
                    </a>
                  </div>
                  <!-- <div class="mesgList">
                      <span @click="xxxxxx">快点我</span>
                    </div> -->
                </el-tab-pane>
              </el-tabs>
              <!-- <div @click="jump">Nihao</div> -->
            </div>
            <!-- 右侧导航 -->
            <div class="right">
              <!-- 河南省普通高中招生信息服务平台 -->
              <!-- 高中招生 -->
              <a href="http://gzzs.jyt.henan.gov.cn/" target="_blank">
                <div class="top" style="font-size: 20px">
                  普通高中招生信息服务平台
                </div>
              </a>
              <div class="down_ks">
                <a
                  class="blanka"
                  href="http://gzzs.jyt.henan.gov.cn/zk/"
                  target="_blank"
                >
                  <div>普通高中招生考生服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://gzzs.jyt.henan.gov.cn/zzcj/"
                  target="_blank"
                >
                  <div>中招信息采集平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://gzzs.jyt.henan.gov.cn/dlsw/"
                  target="_blank"
                >
                  <div>地理生物学业水平考试平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://gzzs.jyt.henan.gov.cn/gzzy/"
                  target="_blank"
                >
                  <div>普通高中志愿填报平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <!-- class="anone" -->
                <a
                  class="blanka"
                  target="_blank"
                  href="http://gzzs.jyt.henan.gov.cn/gzlq/"
                >
                  <div>普通高中录取管理平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <!-- 综合 -->
                <a
                  href="http://xsgl.jyt.henan.gov.cn"
                  target="_blank"
                  style="background-color: #2e76f3"
                >
                  <div class="top" style="font-size: 20px">
                    普通高中学生综合信息服务平台
                  </div>
                </a>
                <!-- <a
                    class="blanka"
                    href="http://xsgl.jyt.henan.gov.cn/gz/"
                    target="_blank"
                  >
                    <div>高中学生服务平台</div>
                    <div class="rightright">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </a> -->
                <a class="anone">
                  <div>高中学生服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://xsgl.jyt.henan.gov.cn/xkfw/"
                  target="_blank"
                >
                  <div>普通高中选课服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://xsgl.jyt.henan.gov.cn/byxs/"
                  target="_blank"
                >
                  <div>普通高中毕业生服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://xsgl.jyt.henan.gov.cn/xysp/"
                  target="_blank"
                >
                  <div>普通高中学业水平考试服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <!-- <a class="anone">
                    <div>普通高中学分服务平台</div>
                    <div class="rightright">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </a> -->
                <a
                  class="blanka"
                  href="http://xsgl.jyt.henan.gov.cn/xfgl/"
                  target="_blank"
                >
                  <div>普通高中学分服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://xsgl.jyt.henan.gov.cn/sqfw/"
                  target="_blank"
                >
                  <div>普通高中优秀学生等申报服务</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://xsgl.jyt.henan.gov.cn/flex/"
                  target="_blank"
                >
                  <div>高中学生档案查询平台(2008-2020级)</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a style="background-color: #2e76f3">
                  <div class="top" style="font-size: 20px">
                    综合素质评价服务平台
                  </div></a
                >
                <a
                  class="blanka"
                  href="http://gzzp.jyt.henan.gov.cn"
                  target="_blank"
                >
                  <div>普通高中学生综合素质评价系统</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <!-- 学籍 -->
                <div class="top" style="font-size: 20px">学籍管理服务平台</div>
                <a
                  class="blanka"
                  href="http://zxx.jyt.henan.gov.cn/"
                  target="_blank"
                >
                  <div>学籍管理服务平台(国网)</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://gzgl.jyt.henan.gov.cn/gzxj/"
                  target="_blank"
                >
                  <div>普通高中学籍管理服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <!-- 义务 -->
                <a
                  href="http://ywzs.jyt.henan.gov.cn/"
                  target="_blank"
                  style="background-color: #2e76f3"
                >
                  <div class="top" style="font-size: 20px">
                    义务教育招生服务平台
                  </div></a
                >
                <a
                  class="blanka"
                  href="http://ywzs.jyt.henan.gov.cn/xxzs/"
                  target="_blank"
                >
                  <div>小学招生学生服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://ywzs.jyt.henan.gov.cn/czzs/"
                  target="_blank"
                >
                  <div>初中招生学生服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://ywzs.jyt.henan.gov.cn/xxgl/"
                  target="_blank"
                >
                  <div>小学招生管理服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
                <a
                  class="blanka"
                  href="http://ywzs.jyt.henan.gov.cn/czgl/"
                  target="_blank"
                >
                  <div>初中招生管理服务平台</div>
                  <div class="rightright">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div class="footer">
          <span style="padding-right: 20px">
            主办: 河南省教育资源保障中心
          </span>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: white"
          >
            {{ icphref }}
          </a>
          ，@copyrigh 2022-2026 All Rights Reserved
          （建议使用谷歌浏览器，使用1920*1024及以上分辨率）
          <a href="http://hagaozhong.com/Home" style="color: white">
            河南基础教育服务平台
          </a>
        </div>
      </el-footer>
    </el-container>
    <div class="cLine">
      <div class="title">分享至</div>
      <div class="line_item">
        <el-popover placement="right" width="150" trigger="hover">
          <div ref="qrcode"></div>
          <div class="qrcodediv">
            <div>用微信扫码二维码</div>
            <div>分享至好友和朋友圈</div>
          </div>
          <div class="item" slot="reference" @hover="wxOpenBtn">
            <img src="@/nav/svg/wx.svg" />
          </div>
        </el-popover>
        <el-popover placement="right" width="100" trigger="hover">
          <div class="msgfont">
            <div>分享到新浪微博</div>
          </div>
          <div @click="wbOpenBtn" class="item" slot="reference">
            <img src="@/nav/svg/wb.svg" />
          </div>
        </el-popover>
        <el-popover placement="right" width="100" trigger="hover">
          <div class="msgfont">
            <div>分享到QQ空间</div>
          </div>
          <div @click="shareToQq" class="item" slot="reference">
            <img src="@/nav/svg/qq.svg" />
          </div>
        </el-popover>
        <el-popover placement="right" width="100" trigger="hover">
          <div class="msgfont">
            <div>收藏到浏览器</div>
          </div>
          <div @click.stop="openNewTab" class="item" slot="reference">
            <img src="@/nav/svg/sc.svg" />
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import Qrcode from "qrcodejs2";
import axios from "axios";
export default {
  name: "newNav1",
  components: {
    Qrcode,
  },
  data() {
    return {
      QrStatus: false,
      mesg: "1",
      icphref: "豫ICP备2023037172号-1",
      activeName: "3",
      swiperList: ["swiper1.jpg", "swiper2.jpg", "swiper3.jpg"],
      msgList: [
        {
          name: "文件通知",
          id: "1",
          children: [
            // {
            //   id: "4",
            //   name: "教育部等六部门关于印发《义务教育质量评价指南》的通知",
            //   url: "http://www.moe.gov.cn/srcsite/A06/s3321/202103/t20210317_520238.html",
            // },
            // {
            //   id: "1",
            //   name: "教育部关于印发《特殊教育办学质量评价指南》的通知",
            //   url: "http://www.moe.gov.cn/srcsite/A06/s3331/202211/t20221107_975922.html",
            // },
            // {
            //   id: "2",
            //   name: "教育部关于印发《幼儿园保育教育质量评估指南》的通知",
            //   url: "http://www.moe.gov.cn/srcsite/A06/s3327/202202/t20220214_599198.html",
            // },
            // {
            //   id: "3",
            //   name: "教育部关于印发《普通高中学校办学质量评价指南》的通知",
            //   url: "http://www.moe.gov.cn/srcsite/A06/s3732/202201/t20220107_593059.html",
            // },
          ],
        },
        {
          name: "通知公告",
          id: "0",
        },
      ],
      login: [
        {
          name: "高中教育",
          yname: "普通高中",
          stage: "3",
          icon: "高中",
          children: [
            // {
            //   name: "高中学生管理",
            //   sys_name: "XSGL",
            //   icon: "学生管理",
            //   url: "/gzxsgl/",
            // },
            {
              name: "高中学业增值评价",
              sys_name: "edu",
              icon: "教学",
              url: "/gzcj/",
            },
            {
              name: "高中学校办学质量评价",
              sys_name: "high_s_edu",
              icon: "学校办学质量",
              url: "/gzzl/",
            },
            {
              name: "高中选课服务",
              sys_name: "gzxkfw",
              icon: "选课",
              url: "http://xsgl.jyt.henan.gov.cn/xkfw/",
            },
            {
              name: "高中学生入口",
              sys_name: "eduevlt",
              icon: "学生入口",
              url: "",
            },
            {
              name: "生涯规划",
              sys_name: "",
              icon: "生涯",
              url: "https://sygh.hnxcp.com/",
            },
            // {
            //   name: "高中学生发展质量评价（学生）",
            //   sys_name: "pri_st_edu",
            //   icon: "学生综合信息",
            //   url: "/gzzhpj/",
            // },
          ],
        },
        {
          name: "初中教育",
          stage: "2",
          yname: "义务",
          icon: "义务",
          children: [
            {
              name: "初中学生管理",
              sys_name: "jun_XSGL",
              icon: "学生管理",
              url: "/czxsgl/",
            },
            {
              name: "初中学业增值评价",
              sys_name: "jun_edu",
              icon: "教学",
              url: "/czcj/",
            },
            {
              name: "初中学校办学质量评价",
              sys_name: "jun_s_edu",
              icon: "学校办学质量",
              url: "/czzl/",
            },
            {
              name: "县域义务教育质量评价",
              sys_name: "eduevlt",
              icon: "机构",
              url: "/xyzl/",
            },
            {
              name: "初中学生发展质量评价（学校）",
              sys_name: "jun_st_edu",
              icon: "学生自评",
              url: "/czxszl/",
            },
            {
              name: "初中学生发展质量评价（学生）",
              sys_name: "pri_stl_edu",
              icon: "学生综合信息",
              url: "/czzhpj/",
            },
            {
              name: "初中学生体质健康监测",
              sys_name: "zktycp",
              icon: "体育",
              url: "/cztcgl/",
            },
            {
              name: "初中劳动与实践服务",
              sys_name: "czyxsy",
              icon: "劳动实践",
              url: "/czyxsj/",
            },
          ],
        },
        {
          name: "小学教育",
          stage: "1",
          yname: "小学",
          icon: "小学",
          children: [
            {
              name: "小学学生管理",
              sys_name: "jun_XSGL",
              icon: "学生管理",
              url: "/xxxsgl/",
            },
            {
              name: "小学学校办学质量评价",
              sys_name: "pri_s_edu",
              icon: "学校办学质量",
              url: "/xxzl/",
            },
            {
              name: "小学学生发展质量评价（学校）",
              sys_name: "pri_st_edu",
              icon: "学生自评",
              url: "/xxxszl/",
            },

            {
              name: "小学学生发展质量评价（学生）",
              sys_name: "pri_stl_edu",
              icon: "学生综合信息",
              url: "/xxzhpj/",
            },
            {
              name: "小学学生体质健康监测",
              sys_name: "zktycp",
              icon: "体育",
              url: "/xxtcgl/",
            },
            {
              name: "小学劳动与实践服务",
              sys_name: "xxyxsj",
              icon: "劳动实践",
              url: "/xxyxsj/",
            },
          ],
        },
        {
          name: "学前教育",
          stage: "0",
          yname: "学前",
          icon: "幼儿园",
          children: [
            {
              name: "保育教育质量评估",
              sys_name: "kg_s_edu",
              icon: "幼儿园管理",
              url: "/xqzl/",
            },
            {
              name: "县域学前教育督导评估",
              sys_name: "",
              icon: "教育",
              url: "/ddzl/",
            },
          ],
        },
        {
          name: "特殊教育",
          stage: "4",
          icon: "特殊",
          children: [
            {
              name: "特殊教育办学质量评价",
              sys_name: "sp_s_edu",
              icon: "学校办学质量",
              url: "",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.callApi();
    if (location.hostname.indexOf("hnxcp.cn") >= 0) {
      this.icphref = "豫ICP备2023037172号 - 1";
    } else if (location.hostname.indexOf("hnxcp.com") >= 0) {
      this.icphref = "豫ICP备2023037172号 - 2";
    }
    if (window.location.protocol === "http:") {
      this.login[0].children.forEach((item) => {
        if (item.name == "生涯规划") {
          item.url = "http://sygh.hnxcp.com/";
        }
      });
      // 表示当前页面使用的是HTTP协议
    } else {
      // 表示当前页面使用的是HTTPS协议
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.createQrCode();
    });
  },
  methods: {
    jump(item) {
      // let jsonDta=JSON.stringify(item);
      let routeUrl = this.$router.resolve({
        path: `/viewdocument`,
        query: {
          inforid: item.inforid,
        },
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({ path: `/viewdocument`, query: { inforid: item.inforid } });
    },
    //打包的时候要换ip
    callApi() {
      // const apiUrl = "http://192.168.1.152/cjfxapi/Msg/ZY_GetInfoRows";
      var apiUrl;
      apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // if (location.href.indexOf('192.168.1.')>-1) {
      //      apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   } else if(location.href.indexOf('hnxcp.com')>-1){
      //      apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   }else if(location.href.indexOf('hnxcp.cn')>-1){
      //      apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   }else if(location.href.indexOf('www.hnxcp.com')>-1){
      //    apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   }else if(location.href.indexOf('www.hnxcp.cn')>-1){
      //    apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   }
      //   if (location.href.indexOf('www.hnxcp.com')>-1) {
      //       apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   }
      //   if (location.href.indexOf('www.hnxcp.cn')>-1) {
      //       apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      //   }
      // if (location.href.indexOf('8008')>-1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // }
      axios
        .get(apiUrl, {
          params: {
            type: "1",
          },
        })
        .then((response) => {
          // console.log(response,"response");
          this.infoData = response.data.data;
          // console.log("object", this.msgList);
          this.msgList[0].children = response.data.data;
          // console.log( this.msgList[0].children,"111");
        })
        .catch((error) => {
          this.error = "发生错误: " + error.message;
        });
    },
    //查看咨询
    // xxxxxx() {
    //   console.log("123");
    //   this.$router.replace("/viewdocument");
    // },
    //获取列表数据
    // async getList() {
    //   let params = {};
    //   let res = await getInfo();
    //   if (res && res.code > 0) {
    //     console.log("接口调取成功", res);
    //   }
    // },
    // 检测浏览器版本判断是否可以收藏到浏览器标签
    browerType() {
      //检测浏览器
      var iUserAgent = navigator.userAgent;
      var iAppVersion = parseFloat(navigator.appVersion);
      var isOpera = iUserAgent.indexOf("Opera") > -1;
      var isKHTML =
        iUserAgent.indexOf("KHTML") > -1 ||
        iUserAgent.indexOf("Konqueror") > -1 ||
        iUserAgent.indexOf("AppleWebKit") > -1;
      if (isKHTML) {
        var isChrome = iUserAgent.indexOf("Chrome") > -1;
        var isSafari = iUserAgent.indexOf("AppleWebKit") > -1 && !isChrome;
        var isKonq = iUserAgent.indexOf("Konqueror") > -1;
      }
      var isIE =
        iUserAgent.indexOf("compatible") > -1 &&
        iUserAgent.indexOf("MSIE") > -1 &&
        !isOpera;
      var isMoz = iUserAgent.indexOf("Gecko") > -1 && !isKHTML;
      var isNS4 =
        !isOpera &&
        !isMoz &&
        !isKHTML &&
        !isIE &&
        iUserAgent.indexOf("Mozilla") == 0 &&
        navigator.appName == "Netscape" &&
        fAppVersion >= 4.0 &&
        fAppVersion <= 5.0;
      //此处为检测平台
      var isWin =
        navigator.platform == "Win32" || navigator.platform == "Windows";
      var isMac =
        navigator.platform == "Mac68K" ||
        navigator.platform == "MacPPC" ||
        navigator.platform == "Macintosh";
      var isUnix = navigator.platform == "X11" && !isWin && !isMac;
      if (isOpera) {
        return "opera";
      } else if (isChrome) {
        return "chrome";
      } else if (isSafari) {
        return "safari";
      } else if (isKonq) {
        return "konq";
      } else if (isIE) {
        //此处没用userAgent来检测，主要是考虑IE9浏览器按F12可以切换到IE7，IE8;用userAgent会检测不出来
        if (/MSIE 6.0/gi.test(navigator.appVersion)) {
          return "IE6";
        } else if (document.all && !document.querySelector) {
          return "IE7";
        } else if (
          document.all &&
          document.querySelector &&
          !document.addEventListener
        ) {
          return "IE8";
        } else {
          return "IE9+";
        }
      } else if (isMoz) {
        return "mozilla";
      } else if (isNS4) {
        return "ns4";
      }
    },
    // 收藏或者提示文字
    openNewTab() {
      var browser = this.browerType();
      if (browser == "mozilla") {
        // Mozilla Firefox Bookmark
        // rel="sidebar"时，单击时Mozilla Firefox就会打开添加收藏
        if ($(this).attr("rel") == "sidebar") {
          if (window.sidebar && window.sidebar.addPanel) {
            window.sidebar.addPanel(document.title, window.location.href, "");
          }
        }
        return true;
      } else if (/IE/g.test(browser)) {
        // IE Favorite
        if (window.external && "AddFavorite" in window.external) {
          window.external.AddFavorite(location.href, document.title);
        }
      } else if (browser == "opera" && window.print) {
        // Opera Hotlist
        this.title = document.title;
        return true;
      } else if (browser == "chrome" || browser == "safari") {
        // webkit - safari/chrome
        alert(
          "请按下 " +
            (navigator.userAgent.toLowerCase().indexOf("mac") != -1
              ? "Command/Cmd"
              : "CTRL") +
            " + D 来收藏此界面."
        );
      }
      return false;
    },
    // 微信分享
    wxOpenBtn() {
      if (!this.QrStatus) {
        this.createQrCode();
      }
    },
    //生成二維碼
    createQrCode() {
      this.QrStatus = true;
      let url = window.location.href;
      new Qrcode(this.$refs.qrcode, {
        // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
        text: url,
        width: 150,
        height: 150,
        colorDark: "#333", //二维码颜色
        colorLight: "#fff", //二维码背景颜色
        correctLevel: Qrcode.CorrectLevel.H, //容错率,L/M/H
      });
    },
    // 微博分享
    wbOpenBtn() {
      // 分享到新浪微博
      // shareUrl是微博的分享地址，（有资料说需要真实的appkey，必选参数，这里我没用appkey也可以正常分享）
      var _shareUrl = "http://service.weibo.com/share/share.php?";
      _shareUrl += "url=" + encodeURIComponent(window.location.href); //参数url设置分享的内容链接
      _shareUrl += "&title=" + encodeURIComponent(document.title); //参数title设置分享的标题
      // 保留当前页面,打开一个非tab页面（按需求来，可以新开标签页，也可以在当前页新开页面）
      window.open(
        _shareUrl,
        "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
      );
    },
    shareToQq() {
      var p = {
        url: encodeURIComponent(window.location.href),
        showcount: "0" /*是否显示分享总数,显示：'1'，不显示：'0' */,
        desc: encodeURIComponent(document.title) /*默认分享理由(可选)*/,
        summary: encodeURIComponent(document.title) /*分享摘要(可选)*/,
        title: encodeURIComponent(document.title) /*分享标题(可选)*/,
        style: "101",
        width: 199,
        height: 30,
      };
      var s = [];
      for (var i in p) {
        s.push(i + "=" + encodeURIComponent(p[i] || ""));
      }
      var target_url =
        "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" +
        s.join("&");
      window.open(target_url);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item {
  width: 75%;
  left: -12.5%;
}
.msgfont {
  font-size: 14px;
  text-align: center;
}
.qrcodediv {
  text-align: center;
  height: 36px;
  line-height: 18px;
  font-size: 12px;
  margin-top: 5px;
}
// 侧栏
.cLine {
  position: fixed;
  left: 100px;
  top: 50%;
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #313131;
    margin-bottom: 10px;
  }
  .line_item {
    > div,
    .item {
      height: 40px;
      width: 40px;
      border-radius: 20px 20px;
      margin: 10px 0;
      border: 1px solid #154b0e2d;
      background-color: rgba(255, 255, 255, 0.685);
      img {
        width: 20px;
        height: 20px;
        margin: 10px;
      }
    }
  }
  .line_item div:hover,
  .line_item .item:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 1500px) {
  .cLine {
    left: 5px;
  }
}
@media screen and (max-width: 1320px) {
  .cLine {
    display: none;
  }
}
.titlefont {
  width: 500px;
  text-shadow: 1px 2px 1px pink;
  color: white;
}
:deep(.el-tabs__nav-wrap::after) {
  background-color: #cccccc;
  height: 1px;
}
.mesgList {
  > a {
    display: block;
    height: 35px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}
.stageBoxP {
  padding: 20px 0;
  min-height: 300px;
}
.stageLabel {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
:deep(.el-tabs__active-bar) {
  width: 100px;
}

.boxStage {
  // 大盒子
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; // 替代原先的space-between布局方式
}
.ebox {
  // 每个item
  padding-top: 12px;
  display: block;
  text-align: center;
  flex: 1;
  height: 120px;
  margin: 10px 30px 0px 0; // 间隙为5px
  // background-color: #999;
  width: calc(
    (100% - 200px) / 4
  ); // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
  min-width: calc((100% - 100px) / 4); // 加入这两个后每个item的宽度就生效了
  max-width: calc((100% - 100px) / 4); // 加入这两个后每个item的宽度就生效了
  &:nth-child(4n) {
    // 去除第3n个的margin-right
    margin-right: 0;
  }
  .elabel {
    margin-top: 10px;
    height: 20px;
    line-height: 20px;
  }

  div {
    // overflow: hidden;
    :nth-child(1) {
      position: relative;
    }
    :nth-child(2) {
      display: none;
    }
  }
}

.ebox:hover {
  background-color: #567fe6;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  // div {
  //   img {
  //     color: white;
  //   }
  // }
  div {
    :nth-child(1) {
      display: none;
    }
    :nth-child(2) {
      display: block;
      margin: auto;
    }
  }
}

.title_right {
  float: right;
  font-size: 1.1vw;
  height: 50px;
  margin-top: 30px;
  border-radius: 25px 0 0 25px;
  background-color: rgba(255, 255, 255, 0.185);
  width: 200px;
  position: relative;
  > div {
    img {
      height: 38px;
      width: 34px;
      margin: 7px 0 0 15px;
    }
  }
  .title_right_text {
    position: absolute;
    top: 0;
    right: 15px;
    // width: 0px;
    line-height: 50px;
    font-weight: 600;
    font-size: 14px;
  }
}

.bg {
  font-family: "微软雅黑", Arial, "宋体";
  width: 100%;
  padding: 0;
  margin: 0;
  // height: 2050px;
  min-height: 963px;
  background-image: url("../nav/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 32%;
  background-color: #ffffff;
  .container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    .el-header {
      padding: 0;
      margin: 0;
      height: 110px !important;
      overflow: hidden;
      .header {
        width: 1200px;
        margin: 0 auto;
        color: white;
        > div {
          display: inline-block;
        }
        .logo {
          font-size: 28px;
          font-weight: 600;
          img {
            float: left;
            margin-top: 10px;
          }
          > div {
            width: 400px;
            line-height: 100px;
            padding-left: 80px;
          }
        }
        .nav {
          position: relative;
          top: 10px;
          > div {
            display: inline;
            margin-right: 40px;
            a {
              color: white;
              font-size: 32px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .el-main {
      width: 100%;
      margin: 0;
      padding: 0;

      .conter {
        margin: 0 auto;
        width: 1200px;
        > .topnav {
          width: 100%;
          height: 45px;
          line-height: 45px;
          border-radius: 2px;
          font-weight: 600;
          background-image: linear-gradient(#579ef8, #568cec, #5785e7);
          display: flex;
          > a {
            display: block;
            color: white;
            flex: 1;
            font-size: 18px;
            text-align: center;
            border-right: 2px solid #ffffff7c;
          }
          a:hover {
            color: #2e76f3;
            background-color: rgb(245, 241, 243);
          }
        }
        .swiper {
          margin: 20px auto;
          border-radius: 5px;
          img {
            width: 100%;
          }
        }
        .cardList {
          clear: both;
          overflow: hidden;
          .left {
            width: 63%;
            float: left;
            .card_item {
              clear: both;
              overflow: hidden;
              height: 180px;
              width: 100%;
              border-top: 8px solid #2e76f3;
              padding: 10px 5px 5px 5px;
              border-radius: 6px;
              background-color: white;
              margin-bottom: 25px;
              box-shadow: 2px 1px 3px 0px rgba(190, 190, 190, 40);
              .card_item_left {
                float: left;
                text-align: center;
                width: 170px;
                height: 100%;
                border-right: 2px solid #2e76f369;
                > div {
                  margin-top: 30px;
                  line-height: 10px;
                  font-size: 20px;
                  font-weight: 600;
                  color: #065acc;
                }
              }
              .card_item_right {
                float: right;
                width: 75%;
                overflow: hidden;
                height: 100%;
                > div {
                  float: left;
                  width: 33%;
                  height: 45%;
                  line-height: 45%;
                  text-align: center;
                  a {
                    font-weight: 400;
                    font-size: 16px;
                  }
                  a:hover {
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .right {
            float: right;
            width: 33%;
            border-top: none;
            .top {
              height: 50px;
              line-height: 50px;
              text-align: center;
              color: white;
              font-weight: 500;
              font-size: 22px;
              background-color: #2e76f3;
              margin-bottom: 6px;
            }
            .down_ks {
              clear: both;
              overflow: hidden;
              > a {
                padding-left: 10px;
                display: block;
                margin-bottom: 6px;
                height: 50px;
                line-height: 50px;
                background-color: #e7f0fd;
                clear: both;
                overflow: hidden;
                > div {
                  // float: left;
                }
                i {
                  color: black !important;
                }
                .rightright {
                  margin-right: 15px;
                  color: #2e76f3;
                  float: right;
                }
              }
              .anone {
                background-color: #c5c3c350;
                color: #6f6f6f;
              }
              .blanka:hover {
                background-color: #8c95ec91;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .el-footer {
      overflow: hidden;
      margin: 0;
      padding: 0;
      background-color: #2e76f3;
      height: 50px !important;

      .footer {
        width: 1200px;
        font-size: 12px;
        line-height: 50px;
        text-align: center;
        margin: 0 auto;
        color: white;
        // display: flex;

        // > div {
        //   flex: 1;
        //   padding: 20px 0px;
        //   margin-top: 40px;
        // }
      }
    }
  }
}
.itemhover {
  color: #6f6f6f !important;
}
.itemhover:hover {
  color: #2e76f3;
}
a {
  text-decoration: none;
}
#linkClass {
  color: #551aa9;
  padding-left: 10px;
}
#linkClass:hover {
  // color: #03036f;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
#a_link:hover .dot {
  background-color: #03036f !important;
}
.bg {
  height: 1992px !important;
}
</style>
