var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('el-container',{staticClass:"container"},[_c('el-header',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/nav/log.png"),"height":"80px"}}),_c('div',[_c('div',{staticClass:"titlefont"},[_vm._v("AI智慧教育暨教育质量监测评价平台")]),_c('div')])]),_c('div',{staticClass:"title_right"},[_c('div',[_c('img',{attrs:{"src":require("@/nav/dp.png")}})]),_c('div',{staticClass:"title_right_text"},[_vm._v("综合实践基地认证")])])])]),_c('el-main',{staticStyle:{"overflow":"auto","margin-top":"30px","min-height":"774px"}},[_c('div',{staticClass:"conter"},[_c('div',{staticClass:"contentBox"},[(_vm.data.contenthteml)?_c('emavon-editor',{staticStyle:{"height":"100%","min-height":"auto"},attrs:{"boxShadowStyle":"none"},domProps:{"innerHTML":_vm._s(_vm.data.contenthteml)}}):_vm._e(),(_vm.data.url)?_c('p',{staticStyle:{"padding-top":"20px"}},[_vm._v(" 附件："),(
                _vm.data.url[0].file_name
                  .split('.')
                  .pop()
                  .toLowerCase()
                  .indexOf('xls') > -1
              )?_c('a',{attrs:{"id":"bgcolor","href":_vm.fileDta,"target":"downloadFile","download":""}},[_vm._v(_vm._s(_vm.data.url[0].file_name))]):_c('a',{attrs:{"id":"bgcolor"},on:{"click":_vm.showDraw}},[_vm._v(_vm._s(_vm.data.url[0].file_name))])]):_vm._e()],1)])]),_c('el-footer',[_c('div',{staticClass:"footer"},[_c('span',{staticStyle:{"padding-right":"20px"}},[_vm._v(" 主办: 河南省教育资源保障中心 ")]),_c('a',{staticStyle:{"color":"white"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v(" "+_vm._s(_vm.icphref || "豫ICP备2023037172号-1 ")+" ")]),_vm._v(" ，@copyrigh 2022-2026 All Rights Reserved （建议使用谷歌浏览器，使用1920*1024及以上分辨率） "),_c('a',{staticStyle:{"color":"white"},attrs:{"href":"http://hagaozhong.com/Home"}},[_vm._v(" 河南基础教育服务平台 ")])])])],1),(_vm.data.url)?_c('el-drawer',{attrs:{"visible":_vm.drawer,"size":_vm.size,"direction":_vm.direction,"with-header":false,"modal":false},on:{"update:visible":function($event){_vm.drawer=$event}}},[(
        _vm.data.url[0].file_name.split('.').pop().toLowerCase().indexOf('doc') <
        0
      )?_c('iframe',{staticStyle:{"position":"absolute","height":"100%","width":"100%"},attrs:{"src":_vm.fileDta}}):_vm._e(),(
        _vm.data.url[0].file_name.split('.').pop().toLowerCase().indexOf('doc') >
        -1
      )?_c('div',{staticStyle:{"padding":"20px"},domProps:{"innerHTML":_vm._s(_vm.fileDta)}}):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }