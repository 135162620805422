<template>
  <div class="bg">
    <!-- 头部 -->
    <el-container class="container">
      <el-header>
        <div class="header">
          <div class="logo">
            <img src="@/nav/log.png" height="80px" />
            <div>
              <div class="titlefont">AI智慧教育暨教育质量监测评价平台</div>
              <div></div>
            </div>
          </div>
          <div class="title_right">
            <div>
              <img src="@/nav/dp.png" />
            </div>
            <div class="title_right_text">综合实践基地认证</div>
          </div>
        </div>
      </el-header>
      <el-main style="overflow: auto; margin-top: 30px; min-height: 774px">
        <div class="conter">
          <div class="contentBox">
            <!-- 类别 -->
            <!-- <div class="posibox">{{ data.type==1?'文件通知':(data.type==2?'公告公示':'报考须知') }}</div> -->
            <!-- <div class="title">{{ data.title || "" }}</div> -->
            <!-- <div class="flex" style="margin-top: 30px;">
                    <div class="box" v-if="data.bsid">发布单位：{{ data.bsid||'' }}</div>

                    <div class="box" v-if="data.issuedept">地区：{{ data.issuedept||'' }}</div>
                </div> -->
            <!-- <div v-if="data.content" class="message ql-editor">
              {{ data.content }} 
            </div> -->
            <!-- <div
              v-if="data.contenthteml"
              class="message"
              v-html="data.contenthteml"
            ></div> -->
            <emavon-editor
              v-if="data.contenthteml"
              v-html="data.contenthteml"
              style="height: 100%; min-height: auto"
              boxShadowStyle="none"
            ></emavon-editor>
            <p v-if="data.url" style="padding-top: 20px">
              附件：<a
                id="bgcolor"
                :href="fileDta"
                target="downloadFile"
                download
                v-if="
                  data.url[0].file_name
                    .split('.')
                    .pop()
                    .toLowerCase()
                    .indexOf('xls') > -1
                "
                >{{ data.url[0].file_name }}</a
              >
              <a @click="showDraw" id="bgcolor" v-else>{{
                data.url[0].file_name
              }}</a>
            </p>
            <!-- <p v-if="data.url" style="padding-top: 20px">
              附件：<a @click="showDraw" id="bgcolor">{{ data.url[0].file_name }}</a>
            </p> -->
          </div>
        </div>
      </el-main>
      <el-footer>
        <div class="footer">
          <span style="padding-right: 20px">
            主办: 河南省教育资源保障中心
          </span>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: white"
          >
            {{ icphref || "豫ICP备2023037172号-1 " }}
          </a>
          ，@copyrigh 2022-2026 All Rights Reserved
          （建议使用谷歌浏览器，使用1920*1024及以上分辨率）
          <a href="http://hagaozhong.com/Home" style="color: white">
            河南基础教育服务平台
          </a>
        </div>
      </el-footer>
    </el-container>
    <el-drawer
      :visible.sync="drawer"
      :size="size"
      v-if="data.url"
      :direction="direction"
      :with-header="false"
      :modal="false"
    >
      <iframe
        v-if="
          data.url[0].file_name.split('.').pop().toLowerCase().indexOf('doc') <
          0
        "
        :src="fileDta"
        style="position: absolute; height: 100%; width: 100%"
      />
      <div
        v-if="
          data.url[0].file_name.split('.').pop().toLowerCase().indexOf('doc') >
          -1
        "
        style="padding: 20px"
        v-html="fileDta"
      />
    </el-drawer>
  </div>
</template>
<script>
import axios from "axios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
export default {
  data() {
    return {
      drawer: false,
      size: "60%",
      direction: "rtl",
      fileDta: "",
      inforid: "",
      data: {
        title: "",
      },
      icphref: "",
    };
  },
  components: {
    "emavon-editor": mavonEditor,
  },
  created() {
    if (location.hostname.indexOf("hnxcp.cn") >= 0) {
      this.icphref = "豫ICP备2023037172号 - 1";
    } else if (location.hostname.indexOf("hnxcp.com") >= 0) {
      this.icphref = "豫ICP备2023037172号 - 2";
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // console.log(JSON.parse(this.$route.query.data));
    this.inforid = this.$route.query.inforid;
    // console.log(this.inforid,"this.inforid");
    this.getData();
    // this.getFile()
  },
  methods: {
    showDraw() {
      this.drawer = true;
    },
    getData() {
      var apiUrl;
      apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // if (location.href.indexOf("192.168.1.") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // } else if (location.href.indexOf("hnxcp.com") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // } else if (location.href.indexOf("hnxcp.cn") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // } else if (location.href.indexOf("www.hnxcp.com") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // } else if (location.href.indexOf("www.hnxcp.cn") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // }
      // if (location.href.indexOf("www.hnxcp.com") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // }
      // if (location.href.indexOf("www.hnxcp.cn") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // }
      // if (location.href.indexOf("8008") > -1) {
      //   apiUrl = "/cjfxapi/Msg/ZY_GetInfoRows";
      // }
      axios
        .get(apiUrl, {
          params: {
            inforid: this.inforid,
            type: "1",
          },
        })
        .then((response) => {
          this.data = response.data.data[0];
          if (this.data.url) {
            this.data.url = JSON.parse(this.data.url);
            this.getFile();
          }
        })
        .catch((error) => {
          this.error = "发生错误: " + error.message;
        });
    },
    getFile() {
      // console.log(this.data);
      if (this.data.url) {
        var apiUrl;
        if (location.href.indexOf("192.168.1.") > -1) {
          apiUrl = "http://192.168.1.20/cjfxapi/MiddleReport/GetFile";
        } else if (location.href.indexOf("hnxcp.com") > -1) {
          apiUrl = "http://hnxcp.com/cjfxapi/MiddleReport/GetFile";
        } else if (location.href.indexOf("hnxcp.cn") > -1) {
          apiUrl = "http://hnxcp.cn/cjfxapi/MiddleReport/GetFile";
        } else if (location.href.indexOf("www.hnxcp.com") > -1) {
          apiUrl = "http://www.hnxcp.com/cjfxapi/Msg/ZY_GetInfoRows";
        } else if (location.href.indexOf("www.hnxcp.cn") > -1) {
          apiUrl = "http://www.hnxcp.cn/cjfxapi/Msg/ZY_GetInfoRows";
        }
        if (location.href.indexOf("www.hnxcp.com") > -1) {
          apiUrl = "http://www.hnxcp.com/cjfxapi/Msg/ZY_GetInfoRows";
        }
        if (location.href.indexOf("www.hnxcp.cn") > -1) {
          apiUrl = "http://www.hnxcp.cn/cjfxapi/Msg/ZY_GetInfoRows";
        }
        if (location.href.indexOf("8008") > -1) {
          apiUrl = "http://117.160.213.57:8008/cjfxapi/MiddleReport/GetFile";
        }
        axios
          .get(apiUrl, {
            params: {
              url: this.data.url[0].url,
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.fileDta = res.data.data;
              console.log(this.fileDta, "this.fileDta");
            }
          })
          .catch((error) => {
            this.error = "发生错误: " + error.message;
          });
      }
    },
  },
};
</script>
<style lang="scss">
.el-carousel__item {
  width: 75%;
  left: -12.5%;
}
.msgfont {
  font-size: 14px;
  text-align: center;
}
.qrcodediv {
  text-align: center;
  height: 36px;
  line-height: 18px;
  font-size: 12px;
  margin-top: 5px;
}
// 侧栏
.cLine {
  position: fixed;
  left: 100px;
  top: 50%;
  .title {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #313131;
    margin-bottom: 10px;
  }
  .line_item {
    > div,
    .item {
      height: 40px;
      width: 40px;
      border-radius: 20px 20px;
      margin: 10px 0;
      border: 1px solid #154b0e2d;
      background-color: rgba(255, 255, 255, 0.685);
      img {
        width: 20px;
        height: 20px;
        margin: 10px;
      }
    }
  }
  .line_item div:hover,
  .line_item .item:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 1500px) {
  .cLine {
    left: 5px;
  }
}
@media screen and (max-width: 1320px) {
  .cLine {
    display: none;
  }
}
.titlefont {
  width: 500px;
  text-shadow: 1px 2px 1px pink;
  color: white;
}
:deep(.el-tabs__nav-wrap::after) {
  background-color: #cccccc;
  height: 1px;
}
.mesgList {
  > a {
    display: block;
    height: 35px;
    line-height: 35px;
    margin-bottom: 10px;
  }
}
.stageBoxP {
  padding: 20px 0;
  min-height: 300px;
}
.stageLabel {
  display: inline-block;
  width: 100px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
:deep(.el-tabs__active-bar) {
  width: 100px;
}

.boxStage {
  // 大盒子
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; // 替代原先的space-between布局方式
}
.ebox {
  // 每个item
  padding-top: 12px;
  display: block;
  text-align: center;
  flex: 1;
  height: 120px;
  margin: 10px 30px 0px 0; // 间隙为5px
  // background-color: #999;
  width: calc(
    (100% - 200px) / 4
  ); // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
  min-width: calc((100% - 100px) / 4); // 加入这两个后每个item的宽度就生效了
  max-width: calc((100% - 100px) / 4); // 加入这两个后每个item的宽度就生效了
  &:nth-child(4n) {
    // 去除第3n个的margin-right
    margin-right: 0;
  }
  .elabel {
    margin-top: 10px;
    height: 20px;
    line-height: 20px;
  }

  div {
    // overflow: hidden;
    :nth-child(1) {
      position: relative;
    }
    :nth-child(2) {
      display: none;
    }
  }
}

.ebox:hover {
  background-color: #567fe6;
  color: white;
  border-radius: 10px;
  font-weight: 600;
  // div {
  //   img {
  //     color: white;
  //   }
  // }
  div {
    :nth-child(1) {
      display: none;
    }
    :nth-child(2) {
      display: block;
      margin: auto;
    }
  }
}

.title_right {
  float: right;
  font-size: 1.1vw;
  height: 50px;
  margin-top: 30px;
  border-radius: 25px 0 0 25px;
  background-color: rgba(255, 255, 255, 0.185);
  width: 200px;
  position: relative;
  > div {
    img {
      height: 38px;
      width: 34px;
      margin: 7px 0 0 15px;
    }
  }
  .title_right_text {
    position: absolute;
    top: 0;
    right: 15px;
    // width: 0px;
    line-height: 50px;
    font-weight: 600;
    font-size: 14px;
  }
}

.bg {
  font-family: "微软雅黑", Arial, "宋体";
  width: 100%;
  padding: 0;
  margin: 0;
  height: 963px !important;
  min-height: 963px;
  background-image: url("../nav/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 32%;
  background-color: #ffffff;
  .container {
    //   height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    .el-header {
      padding: 0;
      margin: 0;
      height: 110px !important;
      overflow: hidden;
      .header {
        width: 1200px;
        margin: 0 auto;
        color: white;
        > div {
          display: inline-block;
        }
        .logo {
          font-size: 28px;
          font-weight: 600;
          img {
            float: left;
            margin-top: 10px;
          }
          > div {
            width: 400px;
            line-height: 100px;
            padding-left: 80px;
          }
        }
        .nav {
          position: relative;
          top: 10px;
          > div {
            display: inline;
            margin-right: 40px;
            a {
              color: white;
              font-size: 32px;
              font-weight: 600;
            }
          }
        }
      }
    }
    .el-main {
      width: 100%;
      margin: 0;
      padding: 0;

      .conter {
        margin: 0 auto;
        width: 1200px;
        > .topnav {
          width: 100%;
          height: 45px;
          line-height: 45px;
          border-radius: 2px;
          font-weight: 600;
          background-image: linear-gradient(#579ef8, #568cec, #5785e7);
          display: flex;
          > a {
            display: block;
            color: white;
            flex: 1;
            font-size: 18px;
            text-align: center;
            border-right: 2px solid #ffffff7c;
          }
          a:hover {
            color: #2e76f3;
            background-color: rgb(245, 241, 243);
          }
        }
        .swiper {
          margin: 20px auto;
          border-radius: 5px;
          img {
            width: 100%;
          }
        }
        .cardList {
          clear: both;
          overflow: hidden;
          .left {
            width: 63%;
            float: left;
            .card_item {
              clear: both;
              overflow: hidden;
              height: 180px;
              width: 100%;
              border-top: 8px solid #2e76f3;
              padding: 10px 5px 5px 5px;
              border-radius: 6px;
              background-color: white;
              margin-bottom: 25px;
              box-shadow: 2px 1px 3px 0px rgba(190, 190, 190, 40);
              .card_item_left {
                float: left;
                text-align: center;
                width: 170px;
                height: 100%;
                border-right: 2px solid #2e76f369;
                > div {
                  margin-top: 30px;
                  line-height: 10px;
                  font-size: 20px;
                  font-weight: 600;
                  color: #065acc;
                }
              }
              .card_item_right {
                float: right;
                width: 75%;
                overflow: hidden;
                height: 100%;
                > div {
                  float: left;
                  width: 33%;
                  height: 45%;
                  line-height: 45%;
                  text-align: center;
                  a {
                    font-weight: 400;
                    font-size: 16px;
                  }
                  a:hover {
                    font-weight: 600;
                  }
                }
              }
            }
          }
          .right {
            float: right;
            width: 33%;
            border-top: none;
            .top {
              height: 50px;
              line-height: 50px;
              text-align: center;
              color: white;
              font-weight: 500;
              font-size: 22px;
              background-color: #2e76f3;
              margin-bottom: 6px;
            }
            .down_ks {
              clear: both;
              overflow: hidden;
              > a {
                padding-left: 10px;
                display: block;
                margin-bottom: 6px;
                height: 50px;
                line-height: 50px;
                background-color: #e7f0fd;
                clear: both;
                overflow: hidden;
                > div {
                  //float: left;
                }
                i {
                  color: black !important;
                }
                .rightright {
                  margin-right: 15px;
                  color: #2e76f3;
                  float: right;
                }
              }
              .anone {
                background-color: #c5c3c350;
                color: #6f6f6f;
              }
              .blanka:hover {
                background-color: #8c95ec91;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .el-footer {
      overflow: hidden;
      margin: 0;
      padding: 0;
      background-color: #2e76f3;
      height: 50px !important;

      .footer {
        width: 1200px;
        font-size: 12px;
        line-height: 50px;
        text-align: center;
        margin: 0 auto;
        color: white;
        // display: flex;

        // > div {
        //   flex: 1;
        //   padding: 20px 0px;
        //   margin-top: 40px;
        // }
      }
    }
  }
}
.itemhover {
  color: #6f6f6f !important;
}
.itemhover:hover {
  color: #2e76f3;
}
a {
  text-decoration: none;
}
#linkClass {
  color: blue;
}
#linkClass:hover {
  color: #03036f;
  cursor: pointer;
}
.contentBox {
  min-height: 100px;
  color: #4b4b4b;
  line-height: 32px;
  border: 1px solid #a4a4a45e;
  padding: 30px 94px 33px 94px;
  margin-bottom: 30px;
  background-color: white;
  .title {
    width: 50%;
    margin: 0 auto;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .message {
  }
  .topBox {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }
}
.flex {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0px;
  justify-content: space-between;
}
.posibox {
  // position: relative;
  // top: 20px;
  // right: 20px;
}
#bgcolor {
  color: blue;
  cursor: pointer;
}
.message {
  .hljs-center {
    text-align: center !important;
  }
  .hljs-left {
    text-align: left !important;
  }
  .hljs-right {
    text-align: right !important;
  }
  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
    code {
      display: inline;
      max-width: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      line-height: inherit;
      word-wrap: normal;
      background-color: transparent;
      border: 0;
    }
  }
  table {
    display: block;
    width: 100%;
    overflow: auto;
    margin-top: 0;
    margin-bottom: 16px;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100% !important;
    th {
      border: 1px solid #ccc !important;
      font-weight: 600;
      padding: 6px 13px;
    }
    td {
      border: 1px solid #ccc !important;
      padding: 6px 13px;
    }
  }
}
</style>
