<template>
<div class='aa'>
    <div class="top">
          <div
            style="
                margin: 0 auto;
                width: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
            "
            >
            <img
                src="../nav/navsvg/网络异常 (1).svg"
                alt="网页加载失败"
                width="800px"
                height="800px"
            />
            <div style="padding-left: 10%">
                <h1 style="font-size: 60px">Oops...</h1>
                <p style="font-size: 30px">出错啦！</p>
                <p style="font-size: 25px">请检查您的网络连接，或稍后再试。</p>
            </div>
            </div>
    </div>
</div>
</template>

<script>
export default {
name: '',
data() {return {};},
components: {},
computed:{},
watch: {},
created() {},
activated() {},
methods: {},
}
</script>

<style lang='scss' scoped>
.aa{
    .top{
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 9999;
      background-color: white;
    //   display: flex;
      .img{
        width: 50%;
        height: 100%;
      }
    }
}

</style>