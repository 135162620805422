//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
import viewdocument from "@/views/viewdocument.vue"
//2.使用路由
import Home from '@/views/home.vue'
import abc from '@/views/404.vue'
Vue.use(VueRouter);
//3.创建VueRouter的实例
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  mode: 'hash',
  //4.配置路由的path和组件
  routes: [
    {
      path: "/",
      component: Home
    },
    {
      name: 'viewdocument',
      path: '/viewdocument',
      component: viewdocument,
    },
    {
      name: '404',
      path: '/404',
      component: abc,
    },
    {
      path: "*",
      redirect: '/404',
      hidden: true
    },
  ]
})
//5.导入路由实例
export default router

